a { cursor: pointer; }

.app-container {
    min-height: 350px;
    height: 100.0vh;
}

.ui.left.visible.sidebar, .ui.right.visible.sidebar {
    box-shadow: 0 0 0px rgba(0,0,0,0);
    -webkit-box-shadow: 0 0 0px rgb(0 0 0 / 0%);
}

.dimmed.dimmable > .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.ui.modal, .ui.active.modal {
    height: auto;
    margin: 0 auto!important;
    top: auto !important;
    left: auto !important;
    transform-origin: center !important;
    transition: all ease .5s;
}

.modal-action-buttons {
    left: auto;
    align-items: center;
    transform-origin: center !important;
    justify-content: center;
    display: flex;
}

.required-field::after {
    content: "*";
    color: red;
}

.ui-menu {
    border:rgb(0 0 0 / 0%)
}

.side-bar-item {
    text-align: left;
}

.btn-delete-patient {
    width: 40px;
    text-align: center;
    box-sizing: content-box;
}
body {
    background-color: #F8F9FA;
}

.nav {
    background-color: #3D2E66 !important;
}

.version{
    color: #000;
    margin-top: auto;
    margin-bottom: auto;
}

.pushed-segment {
    width: calc(100% - 248px);
  }

.tabbar {
    margin-left: 248px;
}
.flex1 {
    flex: 1;
}