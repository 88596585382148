.confirmation-code {
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
    text-align: center;
}

.emailForm{
    margin-left: 10%;
}

.formWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.borderBox{
    background-color: #ffffff;
    border: 1.5px solid #9f9f9f86;
    max-width: 90%;
    width: 650px;
    height: 680px;
    border-radius: 10px;
    display: inline-block;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.formHeader{
    
    font-size: 1.25rem;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    line-height: 1.235;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: 0.938rem;
    padding-top: 0.625rem;
}

.borderPadding{
    padding: 50px 50px;
}

.submitButton{
    background-color: #3F2A56FF;
}

.promptHeader{
    font-size: 0.938rem;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    line-height: 1.235;
    text-align: center;
    color: rgba(0, 0, 0, 0.54);
    padding-bottom: 50px;
}